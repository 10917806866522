var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"uncompleted-list"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap"},[(_vm.vendors)?_c('multiselect',{staticClass:"m-2",staticStyle:{"width":"250px"},attrs:{"options":_vm.vendors.data,"placeholder":_vm.$t('select_vendor'),"label":"store_name","track-by":"id"},on:{"select":_vm.vendorFilterHandler,"remove":_vm.removeVendorFilter},model:{value:(_vm.selectedVendor),callback:function ($$v) {_vm.selectedVendor=$$v},expression:"selectedVendor"}}):_vm._e(),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"my-1 px-2",staticStyle:{"gap":"5px"}},[_c('b-col',{attrs:{"cols":"12","sm":"auto"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('search_placeholder')},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('b-col',[(_vm.searchTerm)?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.clearSearch}},[_vm._v(" "+_vm._s(_vm.$t("clear_search"))+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.searchHandler}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")])],1)],1)],1)],1),_c('vue-good-table',{attrs:{"columns":_vm.tableHeader,"rows":_vm.tableBody,"styleClass":"table-custom-style vgt-table striped condensed","pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.label === 'mainImage')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/main_image"))+" ")]):(props.column.label === 'product')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/product"))+" ")]):(props.column.label === 'vendor')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/vendor"))+" ")]):(props.column.label === 'status')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/status"))+" ")]):(
          props.column.label === 'actions' &&
          _vm.globalPermission.products &&
          (_vm.globalPermission.products.update ||
            _vm.globalPermission.products.destroy)
        )?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("g.products/products_list/actions"))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [_c('b-link',{attrs:{"to":_vm.globalPermission.products && _vm.globalPermission.products.show
            ? {
                name: 'show-product',
                params: {
                  catalogSlug: props.row.catalogSlug,
                  slug: props.row.slug,
                },
              }
            : {}}},[(props.column.field === 'mainImage')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{attrs:{"variant":"info","src":_vm.getMainImage(props.row.images)}})],1):_vm._e(),(props.column.field === 'product')?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.row.product)+" ")]):(props.column.field === '#')?_c('span',{staticClass:"text-nowrap custom-toggle"},[_vm._v(" "+_vm._s(props.row.index)+" ")]):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap",staticStyle:{"color":"#bf2e2e"}}):(
            props.column.field === 'actions' &&
            _vm.globalPermission.products &&
            (_vm.globalPermission.products.update ||
              _vm.globalPermission.products.destroy)
          )?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"Block Level Dropdown Menu","block":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
                  _vm.globalPermission.products &&
                  _vm.globalPermission.products.update
                )?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'show-product',
                  params: {
                    catalogSlug: props.row.catalogSlug,
                    slug: props.row.slug,
                  },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.edit")))])],1):_vm._e(),(
                  _vm.globalPermission.products &&
                  _vm.globalPermission.products.destroy
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onDeleteProduct(props.row.slug)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Trash2Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("g.delete")))])],1):_vm._e()],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"empty-table-message"},[_c('p',[_vm._v(_vm._s(_vm.$t("noData")))])])]),_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"mt-2 d-flex justify-content-center flex-wrap"},[_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"use-router":"","size":"md","align":"center","number-of-pages":_vm.dataMeta.last_page,"per-page":_vm.dataMeta.per_page}})],1)])],2),_c('overlay-component',{attrs:{"isLoading":_vm.overlayLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }