<template>
  <b-link class="completed-list">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <multiselect
        v-if="vendors"
        class="mx-2"
        style="width: 250px"
        :options="vendors.data"
        :placeholder="$t('select_vendor')"
        label="store_name"
        track-by="id"
        @select="vendorFilterHandler"
        @remove="removeVendorFilter"
        v-model="selectedVendor"
      />
      <form @submit.prevent>
        <b-row class="my-1 px-2" style="gap: 5px">
          <b-col cols="12" sm="auto">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search_placeholder')"
            />
          </b-col>
          <b-col>
            <b-button
              variant="outline-secondary"
              @click="clearSearch"
              class="mx-1"
              v-if="searchTerm"
            >
              {{ $t("clear_search") }}
            </b-button>
            <b-button @click="searchHandler" variant="primary" type="submit">
              {{ $t("search") }}
            </b-button>
          </b-col>
        </b-row>
      </form>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="tableHeader"
      :rows="tableBody"
      styleClass="table-custom-style vgt-table striped condensed"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <!-- Column: Name -->
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label === 'mainImage'" class="text-nowrap">
          {{ $t("g.products/products_list/main_image") }}
        </span>
        <span v-else-if="props.column.label === 'product'" class="text-nowrap">
          {{ $t("g.products/products_list/product") }}
        </span>
        <span v-else-if="props.column.label === 'vendor'" class="text-nowrap">
          {{ $t("g.products/products_list/vendor") }}
        </span>
        <span v-else-if="props.column.label === 'status'" class="text-nowrap">
          {{ $t("g.products/products_list/status") }}
        </span>
        <span
          v-else-if="
            props.column.label === 'reviews' &&
            globalPermission.reviews &&
            globalPermission.reviews.index
          "
          class="text-nowrap"
        >
          {{ $t("g.products/products_list/reviews") }}
        </span>
        <span v-else-if="props.column.label === 'actions'" class="text-nowrap">
          {{ $t("g.products/products_list/actions") }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'mainImage'" class="text-nowrap">
          <b-avatar variant="info" :src="getMainImage(props.row.images)" />
        </span>
        <span v-if="props.column.field === 'product'" class="text-nowrap">
          <b-link
            v-if="props.row.product && props.row.product.length < 65"
            :to="
              globalPermission.products && globalPermission.products.show
                ? {
                    name: 'show-product',
                    params: {
                      catalogSlug: props.row.catalogSlug,
                      slug: props.row.slug,
                    },
                  }
                : {}
            "
          >
            {{ props.row.product }}
          </b-link>
          <b-link
            v-else
            v-b-tooltip:hover.top="props.row.product"
            :to="
              globalPermission.products && globalPermission.products.show
                ? {
                    name: 'show-product',
                    params: {
                      catalogSlug: props.row.catalogSlug,
                      slug: props.row.slug,
                    },
                  }
                : {}
            "
          >
            {{
              props.row.product && props.row.product.substring(0, 40) + "..."
            }}
          </b-link>
        </span>
        <span
          class="text-nowrap custom-toggle"
          v-else-if="props.column.field === '#'"
        >
          {{ props.row.index }}
        </span>
        <span
          class="text-nowrap"
          style="color: #2983ad"
          v-else-if="props.column.field === 'status'"
        >
          {{ $t("g.products/products_list/uncomplated") }}
        </span>
        <span
          class="text-nowrap"
          style="color: #003e5b; cursor: pointer"
          v-else-if="
            props.column.field === 'reviews' &&
            globalPermission.reviews &&
            globalPermission.reviews.index
          "
        >
          <b-link
            :to="
              globalPermission.reviews && globalPermission.reviews.index
                ? { name: 'product-review', params: { slug: props.row.slug } }
                : {}
            "
          >
            {{ $t("g.view") }}
          </b-link>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="
            props.column.field === 'actions' &&
            globalPermission.products &&
            (globalPermission.products.update ||
              globalPermission.products.destroy ||
              globalPermission.products.show)
          "
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              text="Block Level Dropdown Menu"
              block
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                v-if="
                  globalPermission.products && globalPermission.products.update
                "
                :to="{
                  name: 'show-product',
                  params: {
                    catalogSlug: props.row.catalogSlug,
                    slug: props.row.slug,
                  },
                }"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>{{ $t("g.edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click.prevent="openExternalLink(props.row.slug)"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>{{ $t("viewInWebSite") }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="
                  globalPermission.products && globalPermission.products.destroy
                "
                @click="onDeleteProduct(props.row.slug)"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span>{{ $t("g.delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate">
        <div class="empty-table-message">
          <!-- Your custom content for the empty state goes here -->
          <p>{{ $t("noData") }}</p>
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="mt-2 d-flex justify-content-center flex-wrap">
          <b-pagination-nav
            :link-gen="linkGen"
            use-router
            :v-model="1"
            size="md"
            align="center"
            :number-of-pages="dataMeta.last_page"
            :per-page="dataMeta.per_page"
          >
          </b-pagination-nav>
        </div>
      </template>
    </vue-good-table>
    <overlay-component :isLoading="overlayLoading" />
  </b-link>
</template>

<script>
import {
  BPaginationNav,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
  BLink,
  VBTooltip,
  BRow,
  BCol,
} from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import { VueGoodTable } from "vue-good-table";
import OverlayComponent from "@/components/shared/OverlayComponent";
import AddUsersComponent from "@/components/UsersComponent/addUser/index.vue";

export default {
  name: "CompletedProductsList",
  components: {
    AddUsersComponent,
    VueGoodTable,
    BPaginationNav,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BAvatar,
    BDropdown,
    BLink,
    BDropdownItem,
    BFormCheckbox,
    OverlayComponent,
    BRow,
    BCol,
    Multiselect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: ["tableBody", "dataMeta", "selectedPage", "vendors"],

  data() {
    return {
      currentPage: 1,
      isLoading: true,
      selectedVendor: null,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableHeader: [
        {
          label: "#",
          field: "#",
        },
        {
          label: "mainImage",
          field: "mainImage",
        },
        {
          label: "product",
          field: "product",
        },
        {
          label: "vendor",
          field: "vendor",
        },

        {
          label: "reviews",
          field: "reviews",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.completed_page) {
      this.currentPage = this.$route.query.completed_page;
    }
    this.tableBody.map((item, index) => {
      item.index = 12 * (this.currentPage - 1) + index + 1;
    });
  },
  mounted() {
    if (this.globalPermission.reviews && !this.globalPermission.reviews.index) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "reviews"
      );
    }
    if (
      this.globalPermission.products &&
      !(
        this.globalPermission.products.update ||
        this.globalPermission.products.destroy ||
        this.globalPermission.products.show
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "actions"
      );
    }
  },
  computed: {
    webSiteBaseUrl() {
      return process.env.VUE_APP_WEB_SITE_BASE_URL;
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?completed_page=${pageNum}`;
    },
    generateLink(slug) {
      return this.webSiteBaseUrl + "product/" + slug;
    },
    async onDeleteProduct(slug) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => {
            this.$http.delete(`admin/products/${slug}`);
            this.$emit("updatePage", this.currentPage);
          }
        );
        this.overlayLoading = true;
      } catch (error) {
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    getMainImage(images) {
      let mainImage;
      if (images?.length > 0) {
        mainImage = images.filter((image) => image.is_main);
      }
      if (mainImage?.length > 0) return mainImage[0].path;
    },
    openExternalLink(slug) {
      window.open(this.generateLink(slug), "_blank");
    },
    searchHandler() {
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.selectedVendor && (filterParams["vendors"] = this.selectedVendor.id);

      this.$emit("searchForProduct", filterParams);
    },
    vendorFilterHandler(selected) {
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.selectedVendor && (filterParams["vendors"] = selected.id);

      this.$emit("searchForProduct", filterParams);
    },
    removeVendorFilter() {
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.$emit("searchForProduct", filterParams);
    },
    clearSearch() {
      this.searchTerm = "";
      const filterParams = {};
      this.selectedVendor && (filterParams["vendors"] = this.selectedVendor.id);
      this.$emit("searchForProduct", filterParams);
    },
  },
  watch: {
    "$route.query.completed_page"(newValue) {
      this.currentPage = newValue || 1;
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.selectedVendor && (filterParams["vendors"] = this.selectedVendor.id);
      this.$emit("updatePage", newValue || 1, filterParams);
    },
    tableBody() {
      this.tableBody.map((item, index) => {
        item.index = 12 * (this.currentPage - 1) + index + 1;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
