<template>
  <div class="uncompleted-list">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <multiselect
        class="m-2"
        style="width: 250px"
        :options="vendors.data"
        :placeholder="$t('select_vendor')"
        label="store_name"
        track-by="id"
        @select="vendorFilterHandler"
        @remove="removeVendorFilter"
        v-model="selectedVendor"
      />
      <form @submit.prevent>
        <b-row class="my-1 px-2" style="gap: 5px">
          <b-col cols="12" sm="auto">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('search_placeholder')"
            />
          </b-col>
          <b-col>
            <b-button
              variant="outline-secondary"
              @click="clearSearch"
              class="mx-1"
              v-if="searchTerm"
            >
              {{ $t("clear_search") }}
            </b-button>
            <b-button @click="searchHandler" variant="primary" type="submit">
              {{ $t("search") }}
            </b-button>
          </b-col>
        </b-row>
      </form>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="tableHeader"
      :rows="tableBody"
      styleClass="table-custom-style vgt-table striped condensed"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <!-- Column: Name -->
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.label === 'mainImage'" class="text-nowrap">
          {{ $t("g.products/products_list/main_image") }}
        </span>
        <span v-else-if="props.column.label === 'product'" class="text-nowrap">
          {{ $t("g.products/products_list/product") }}
        </span>
        <span v-else-if="props.column.label === 'actions'" class="text-nowrap">
          {{ $t("g.products/products_list/actions") }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template slot="table-row" slot-scope="props">
        <b-link
          :to="
            globalPermission.products &&
            globalPermission.products.show && {
              name: 'show-product',
              params: {
                catalogSlug: props.row.catalogSlug,
                slug: props.row.slug,
                underReview: 'true',
              },
            }
          "
        >
          <span v-if="props.column.field === 'mainImage'" class="text-nowrap">
            <b-avatar
              style="object-fit: cover"
              variant="info"
              :src="getMainImage(props.row.images)"
            />
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>
          <span
            v-else-if="props.column.field === 'product'"
            class="text-nowrap product-name"
          >
            {{ props.row.product }}
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="
              props.column.field === 'actions' &&
              (globalPermission.products.update ||
                globalPermission.products.destroy)
            "
            class="position-absolute"
          >
            <span>
              <b-dropdown
                variant="link"
                class="ddl mt-n2 ml-n5"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  @click.prevent="showCatalog(props.row.catalogSlug)"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>{{ $t("g.show_catalog") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    globalPermission.products &&
                    globalPermission.products.update
                  "
                  :to="{
                    name: 'show-product',
                    params: {
                      catalogSlug: props.row.catalogSlug,
                      slug: props.row.slug,
                    },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.products &&
                    globalPermission.products.destroy
                  "
                  @click="onDeleteUser(props.row.slug)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </b-link>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="mt-2 d-flex justify-content-center flex-wrap">
          <div>
            <b-pagination
              size="md"
              align="center"
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="dataMeta.per_page"
            >
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
  BLink,
  BRow,
  BCol,
} from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import { VueGoodTable } from "vue-good-table";
import dateTimeInFormat from "@/composables/format-date.js";
import OverlayComponent from "@/components/shared/OverlayComponent";
import AddUsersComponent from "@/components/UsersComponent/addUser/index.vue";

export default {
  name: "UnderReviewList",
  components: {
    AddUsersComponent,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BLink,
    OverlayComponent,
    BRow,
    BCol,
    Multiselect,
  },
  props: ["tableBody", "dataMeta", "selectedPage", "vendors"],
  data() {
    return {
      searchTerm: "",
      currentPage: 1,
      selectedVendor: null,
      isLoading: true,
      overlayLoading: false,
      pageLength: 10,
      formateDate: dateTimeInFormat,
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "mainImage",
          field: "mainImage",
        },
        {
          label: "product",
          field: "product",
        },
        {
          label: "actions",
          field: "actions",
        },
      ],
    };
  },
  created() {
    this.currentPage = this.selectedPage;
    this.tableBody.map((item, index) => {
      item.index = 12 * (this.currentPage - 1) + index + 1;
    });
    if (
      this.globalPermission.products &&
      !(
        this.globalPermission.products.update ||
        this.globalPermission.products.destroy
      )
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "actions"
      );
    }
  },
  methods: {
    getMainImage(images) {
      let mainImage;

      if (images?.length > 0) {
        mainImage = images.filter((image) => image.is_main);
      }
      if (mainImage?.length > 0) {
        return mainImage[0]?.path;
      } else {
        return images[0]?.path;
      }
    },
    onDeleteUser(id) {
      this.$emit("deleteProduct", id);
    },
    searchHandler() {
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.selectedVendor && (filterParams["vendors"] = this.selectedVendor.id);

      this.$emit("searchForProduct", filterParams);
    },
    vendorFilterHandler(selected) {
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.selectedVendor && (filterParams["vendors"] = selected.id);

      this.$emit("searchForProduct", filterParams);
    },
    removeVendorFilter() {
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.$emit("searchForProduct", filterParams);
    },
    clearSearch() {
      this.searchTerm = "";
      const filterParams = {};
      this.selectedVendor && (filterParams["vendors"] = this.selectedVendor.id);
      this.$emit("searchForProduct", filterParams);
    },
    showCatalog(slug) {
      window.open(
        `${location.origin}/#/catalog/request-catalog/${slug}`,
        "_blank"
      );
    },
  },
  watch: {
    currentPage(newValue) {
      this.currentPage = newValue || 1;
      const filterParams = {};
      this.searchTerm && (filterParams["search"] = this.searchTerm);
      this.selectedVendor && (filterParams["vendors"] = this.selectedVendor.id);
      this.$emit("updatePage", newValue || 1, filterParams);
    },
    tableBody() {
      this.tableBody.map((item, index) => {
        item.index = 12 * (this.currentPage - 1) + index + 1;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-left-align {
  .product-name {
    max-width: 150px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
